// WRITE ARTICLE
export const addAName = {
  order: 4,
  name: "Add a name",
  subcategory: "Make database changes",
  markdown: `# Add a name

  {% callout type="caution" %}
  **Important:** Names are public. Tower Hunt also supports {% inlineRouterLink articleId="add-an-internal-name" %}private internal names{% /inlineRouterLink %}.
  {% /callout %}`,
};
